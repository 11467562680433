import React from "react"

export default function BlogThumb({ featuredImage }) {
    let html = (
      <div className="h-12 w-12 float-left">
        {/* thanks https://heroicons.com/ for the svg */}
        <svg
          className="rounded-full h-6 w-6 m-3"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            // eslint-disable-next-line max-len
            d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
          />
        </svg>
      </div>
    )
    if (featuredImage.url) {
        html = (
          <img
            className="rounded-full h-12 w-12 float-left"
            src={featuredImage.url}
            alt={featuredImage.caption}
          />
        )
    }
    return html
}
