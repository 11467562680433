import React from "react"
import Search from "./Search"

const Nav = ({ location }) => {
  const [isOpen, setOpen] = React.useState(false)

  function toggleMenu() {
    setOpen(!isOpen)
  }

    function openModal() {
        document.getElementById('contactModal').showModal()
    }
    return (
      // started from https://tailwindcomponents.com/component/navabr-with-categories
      <header className="bg-dc-gray">
        <nav className="container mx-auto px-6 py-3">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            <div className="flex justify-between items-center">
              { location === "top" ? <h1><a href="/">DougCone.com</a></h1> : '' }

              {/* <!-- Mobile menu button --> */}
              <div className="flex md:hidden flex-row">
                <button
                  type="button"
                  className="flex-initial text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600 pr-2"
                  aria-label="toggle menu"
                  onClick={toggleMenu}
                >
                  <svg viewBox="0 0 24 24" className="h-6 w-6 fill-current">
                    <path
                      fillRule="evenodd"
                      // eslint-disable-next-line max-len
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                    />
                  </svg>
                </button>
                <a href="/" className="flex-initial">DougCone.com</a>
              </div>
              <div className="flex items-center">
                <div className="mx-10 hidden md:block">
                  <Search />
                </div>
              </div>
            </div>

            {/* <!-- Mobile Menu open: "block", Menu closed: "hidden" --> */}
            <div className={(`md:flex items-center ${(isOpen) ? 'block' : 'hidden'}`)}>
              <div className="flex flex-col mt-2 md:flex-row md:mt-0 md:mx-1">
                <a
                  className="my-1 text-sm text-gray-700 leading-5 hover:text-blue-600 hover:underline md:mx-4 md:my-0"
                  href="/"
                >
                  Home
                </a>
                <a
                  className="my-1 text-sm text-gray-700 leading-5 hover:text-blue-600 hover:underline md:mx-4 md:my-0"
                  href="/about/"
                >
                  About
                </a>
                <a
                  className="my-1 text-sm text-gray-700 leading-5 hover:text-blue-600 hover:underline md:mx-4 md:my-0"
                  href="/blog/"
                >
                  Blog
                </a>
                <a
                  className="my-1 text-sm text-gray-700 leading-5 hover:text-blue-600 hover:underline md:mx-4 md:my-0"
                  href="https://www.nullvariable.com"
                >
                  Consulting
                </a>
              </div>

              <div className="flex items-center py-2 -mx-1 md:mx-0">
                <button
                  type="button"
                  // eslint-disable-next-line max-len
                  className="block w-1/2 px-3 py-2 mx-1 rounded text-center text-sm bg-blue-500 font-medium text-white leading-5 hover:bg-blue-600 md:mx-0 md:w-auto"
                  onClick={openModal}
                >
                  Contact
                </button>
              </div>
            </div>
          </div>
        </nav>
      </header>
    )
}

export default Nav
