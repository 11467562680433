import LogRocket from "logrocket"
import React, { useEffect, useRef, useState } from "react"
import PropTypes from 'prop-types'

// with thanks to react-hcaptcha
const urlAlphabet =
  'ModuleSymbhasOwnPr-0123456789ABCDEFGHNRVfgctiUvz_KqYTJkLxpZXIjQW'

const nanoid = (size = 21) => {
  let id = ''
  // A compact alternative for `for (var i = 0; i < step; i++)`.
  let i = size
  // eslint-disable-next-line no-plusplus
  while (i--) {
    // `| 0` is more compact and faster than `Math.floor()`.
    // eslint-disable-next-line no-bitwise
    id += urlAlphabet[(Math.random() * 64) | 0]
  }
  return id
}

const defaultSiteKey = '10000000-ffff-ffff-ffff-000000000001'

function globalInit() {
  if ( typeof window.hcOnLoad !== "function" ) {
    window.hcOnLoad = () => {
      window.hcaptchaLoaded = true
      try {
        window.hcListeners = window.hcListeners.filter(listener => { listener(); return false })
      } catch (error) {
        if ( process.env.NODE_ENV === "development") {
          // eslint-disable-next-line no-console
          console.error(error)
        } else if (typeof LogRocket !== "undefined") {
          LogRocket.error(error)
        }
      }
    }
  }
  if ( ! window.hCaptchaLoaded && ! document.getElementById( "hcScript" ) ) {
    const script = document.createElement("script");
    script.id = "hcScript"
    script.src = "https://hcaptcha.com/1/api.js?render=explicit&onload=hcOnLoad";
    script.async = true
    document.head.appendChild(script)
  }
}

const HCaptchaField = ({ className, onSubmit, onError, onExpire }) => {
  const elementId = useRef(`hc-${nanoid()}`)
  const captchaId = useRef(false)

  const init = (typeof window !== "undefined") ? window.hCaptchaLoaded : null

  const [apiReady, setApiReady] = useState( init )
  const [isRendered, setIsRendered] = useState(false)

  const handleError = () => {
    onError()
  }
  const handleExpire = () => {
    onExpire()
  }
  const handleSubmit = (token) => {
    onSubmit(token)
  }

  const resetCaptcha = () => {
    if ( !apiReady || !isRendered ) return
    window.hcaptcha.reset(captchaId.current)
  }

  const remove = () => {
    if ( !apiReady || !isRendered ) return
    window.hcaptcha.remove(captchaId.current)
    setIsRendered(false)
  }

  const renderCaptcha = () => {
    if ( !apiReady || isRendered ) return // only render if we're not already rendered, and the api is ready.
    try {
      const newCaptchaId = window.hcaptcha.render(
        document.getElementById(elementId.current),
        {
          "sitekey": window.hcSiteKey || defaultSiteKey,
          "error-callback": handleError,
          "expired-callback": handleExpire,
          "callback": handleSubmit,
        }
      )

      captchaId.current = newCaptchaId
      setIsRendered(true)
    } catch (error) {
      if ( process.env.NODE_ENV === "development") {
        // eslint-disable-next-line no-console
        console.error(error)
      } else if (typeof LogRocket !== "undefined") {
        LogRocket.error(error)
      }
    }
  }

  const handleOnLoad = () => {
    setApiReady(true)
    renderCaptcha()
  }

  useEffect(() => {
    if ( window.hcaptchaLoaded ) {
      setApiReady(true)
    } else {
      globalInit()
    }
  })

  useEffect(() => {
    if ( apiReady ) {
      renderCaptcha()
    } else if ( typeof window.hcListeners === "undefined") {
        window.hcListeners = [handleOnLoad]
    } else {
      window.hcListeners.push(handleOnLoad)
    }
  })

  useEffect(() => {
    return () => {
      if ( !isRendered || !apiReady ) return
      try {
        resetCaptcha()
        remove()
      } catch (err) {
        if ( process.env.NODE_ENV === "development" ) {
          // eslint-disable-next-line no-console
          console.error(err)
        }
      }
      setIsRendered(false)
    }
  }, [isRendered])

  return  <div className={className} id={elementId.current} />
}


HCaptchaField.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func,
  onExpire: PropTypes.func,
}

HCaptchaField.defaultProps = {
  className: "",
  onError: () => {},
  onExpire: () => {},
}

export default HCaptchaField
