import React, { useCallback, useEffect, useMemo, useRef } from "react"
import PropTypes from 'prop-types'
import algoliasearch from "algoliasearch/lite"
import { truncate } from "lodash"
import { InstantSearch, connectStateResults, connectHits, connectSearchBox } from "react-instantsearch-dom"
import { useStaticQuery, graphql } from "gatsby"
import SmallPostCard from "./SmallPostCard";

// const data = StaticQuery()
// const algoliaClient = algoliasearch(
//   'A8ERQNID6E',
//   'bc525f7a7ff4736bddcebdd18d977724'
// );
// console.log(typeof algoliaClient)

// const { algoliaClientConfig } = data.site.siteMetadata
// const algoliaClient = useCallback(() => {
//   console.log(algoliaClientConfig)
//   return algoliasearch(algoliaClientConfig)
// }, [data])

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const SearchBoxD = ({currentRefinement, updateSearch, refine, resultsVisible}) => {

  const prevResultsVisible = usePrevious(resultsVisible)
  useEffect(() => {
    if (prevResultsVisible && !resultsVisible) {
      refine("")
    }
  })

  function handleOnChange(event) {
    updateSearch(event.currentTarget.value)
    refine(event.currentTarget.value)
  }

  return (
    <form noValidate action="" role="search">
      <input
        type="search"
        id="headerSearch"
        value={currentRefinement}
        className="w-32 lg:w-64 px-4 py-3 leading-tight text-sm text-gray-700 bg-gray-100 rounded-md
          placeholder-gray-500 border border-transparent focus:outline-none focus:bg-white focus:shadow-outline
          focus:border-blue-400"
        onChange={handleOnChange}
        placeholder="Search"
        aria-label="Search"
      />
    </form>
  )
}

SearchBoxD.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  updateSearch: PropTypes.func.isRequired,
  refine: PropTypes.func.isRequired,
  resultsVisible: PropTypes.bool.isRequired,
}

const CustomSearchBox = connectSearchBox(SearchBoxD)

function normalizeFI(hit) {
  const result = {
    url: "",
    caption: ""
  }
  if ( hit.featuredImage ) {
    result.url = hit.featuredImage
    result.caption = hit.featuredImageCaption ?? ""
  }
  return result
}

const Hits = ({ hits, onCloseHits }) => {
  function closeResults() {
    onCloseHits(true)
  }

  return (
    <>
      <div
        onClick={closeResults}
        onKeyPress={closeResults}
        role="button"
        className="cursor-pointer float-right"
        aria-pressed="false"
        tabIndex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </div>
      <div className="text-indigo-500 clear-both">
        {hits.map((hit, index) => (
          <SmallPostCard
            key={hit.objectID || index}
            title={hit.title}
            excerpt={hit.excerpt ?? truncate(hit.content, {length: 30})}
            featuredImage={normalizeFI(hit)}
            uri={hit.url}
          />
    ))}
      </div>
    </>
  )
};

Hits.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCloseHits: PropTypes.func.isRequired,
}

const CustomHits = connectHits(Hits);

const Results = connectStateResults(({ resultsVisible, searchState, searchResults, children }) => {
  const classes = 'z-50 h-auto w-1/2 lg:1/4 absolute bg-dc-gray rounded-b-lg p-3 max-h-screen overflow-y-auto'
  let html = ''
  if ( resultsVisible && searchState && searchState.query ) {
    html = (
      <div className={classes}>
        {( searchResults.nbHits > 0 ) ? children : `Sorry, no results found for "${searchState.query}"`}
      </div>
    )
  }
  return html
})

const Search = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            algoliaIndexName
            algoliaClientConfig {
              appId,
              apiKey,
            }
          }
        }
      }
    `
  )
  const { algoliaIndexName } = data.site.siteMetadata
  const algoliaClient = useMemo(() => {
    const { appId, apiKey } = data.site.siteMetadata.algoliaClientConfig
    return algoliasearch(appId, apiKey)
    }, [ data ])

  const searchClient = {
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
          })),
        });
      }
      return algoliaClient.search(requests);
    }
  }

  const [resultsVisible, setResultsVisible] = React.useState(false)

  const handleSearchChange = () => {
    setResultsVisible(true)
  }
  const handleHitsClose = () => {
    setResultsVisible(false)
  }

  return (
    <InstantSearch
      indexName={algoliaIndexName}
      searchClient={searchClient}
    >
      <CustomSearchBox updateSearch={handleSearchChange} resultsVisible={resultsVisible} />
      <Results resultsVisible={resultsVisible}>
        <CustomHits onCloseHits={handleHitsClose} />
      </Results>
    </InstantSearch>
  )
}

export default Search
