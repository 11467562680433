import React from "react"
import { Formik } from 'formik';
import _ from "lodash"
import ContactLeft from "./ContactLeft"
import HCaptchaField from "./HCaptchaField"

function closeModal() {
    document.getElementById('contactModal').close()
}

function modalKeyPress(...args) {
    console.log(args)
}

function sessionAlreadySent() {
  try {
    return window.sessionStorage.getItem("alreadySentEmail")
  } catch {
    return false
  }
}
function setSessionAlreadySent(value) {
  try {
    return window.sessionStorage.setItem("alreadySentEmail", value)
  } catch {
    return false
  }
}

const Modal = () => {
  const [message, setMessage] = React.useState(
    !sessionAlreadySent() ? false : "You've already sent a message recently."
  )
  const [formVisible, setFormVisible] = React.useState( !sessionAlreadySent() )
  const [token, setToken] = React.useState(null)

  const submitForm = async (values, { setSubmitting }) => {
    const data = {
      name: _.get(values, 'name', ''),
      email: _.get(values, 'email', ''),
      message: _.get(values, 'message', ''),
      token,
    }
    if ( sessionAlreadySent() ) {
      setFormVisible(false)
      setMessage("You've already sent a message recently.")
    }

    const result = await fetch('/wp-json/doug/v1/contact', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
    if ( result.status === 200 ) {
      setSessionAlreadySent(true)
      const body = await result.json()
      setFormVisible(false)
      setMessage(body)
    } else {
      // error handling
      const responseJSON = await result.json()
      setMessage(_.get(responseJSON, 'message', 'Error sending message, maybe reach out on twitter?'))
      const highlightField = _.get(responseJSON, 'highlight', false)
      if ( highlightField ) {
        setFormVisible(true)
        setSubmitting(false)
      } else {
        setFormVisible(false)
      }
    }
  }

  const messageDisplay = () => {
    return (
      // eslint-disable-next-line max-len
      <div className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
        <span className="font-semibold mr-2 text-center flex-auto">{message}</span>
      </div>

    )
  }

    return (
      <dialog id="contactModal" className="h-auto w-11/12 md:w-9/12 p-5 bg-white rounded-md">
        <div className="flex flex-col w-full h-auto ">
          <div className="flex w-full h-auto justify-center items-center">
            <div
              className="flex w-10/12 h-auto py-3 justify-center items-center text-2xl font-bold"
            />
            <div
              onClick={closeModal}
              onKeyDown={modalKeyPress}
              role="button"
              className="flex w-1/12 h-auto justify-center cursor-pointer"
              aria-pressed="false"
              tabIndex="0"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
          </div>
          <div
            className="flex w-full h-auto p-2 justify-center items-center bg-blue-100 rounded text-center
              text-gray-500 "
          >
            {/* start */}
            <div className="relative flex items-top justify-center min-h-screen bg-white dark:bg-gray-900
              sm:items-center sm:pt-0 "
            >
              <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
                <div className="mt-8 overflow-hidden">
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <ContactLeft />

                    <div className="p-6 flex flex-col justify-center">
                      {/* insert message or form here */}
                      { (message) ? messageDisplay() : '' }
                      { (formVisible) && (
                        <Formik
                          initialValues={{ name: '', email: '', message: '' }}
                          validate={values => {
                            const errors = {};
                            if (!values.email) {
                              errors.email = 'Required';
                            } else if (
                              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                            ) {
                              errors.email = 'Invalid email address';
                            }
                            if (!values.name) {
                              errors.name = 'Required'
                            }
                            if (!values.message) {
                              errors.message = 'Required'
                            }
                            return errors;
                          }}
                          onSubmit={submitForm}
                        >
                          {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit} id="modalContactForm">
                            <div className="flex flex-col">
                              <label htmlFor="name">
                                <span className="sr-only">Full Name</span>
                                <input
                                  type="name"
                                  name="name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.name}
                                  placeholder="Full Name"
                                  className={`w-full mt-2 py-3 px-3 rounded-lg bg-white border text-gray-800
                                    font-semibold focus:border-indigo-500 focus:outline-none
                                    ${(touched.name && errors.name) ? 'border-red-500' : 'border-gray-400'}
                                  `}
                                />
                              </label>
                              {errors.name && touched.name && errors.name}
                            </div>
                            <div className="flex flex-col">
                              <label htmlFor="email">
                                <span className="sr-only">Email</span>
                                <input
                                  type="email"
                                  name="email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  placeholder="Email"
                                  className={`w-full mt-2 py-3 px-3 rounded-lg bg-white border text-gray-800
                                    font-semibold focus:border-indigo-500 focus:outline-none
                                    ${(touched.email && errors.email) ? 'border-red-500' : 'border-gray-400'}
                                  `}
                                />
                              </label>
                              {errors.email && touched.email && errors.email}
                            </div>
                            <div className="flex flex-col mt-2">
                              <label htmlFor="message">
                                <span className="sr-only">Message</span>
                                <textarea
                                  name="message"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.message}
                                  rows="3"
                                  placeholder="Enter your message"
                                  className={`w-full mt-2 py-3 px-3 rounded-lg bg-white border text-gray-800
                                    font-semibold focus:border-indigo-500 focus:outline-none
                                    ${(touched.message && errors.message) ? 'border-red-500' : 'border-gray-400'}
                                  `}
                                />
                              </label>
                              {errors.message && touched.message && errors.message}
                            </div>
                            <div className="grid h-full">
                              <HCaptchaField className="m-auto" onSubmit={setToken} />
                            </div>
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="md:w-32 bg-indigo-600 hover:bg-blue-dark text-white font-bold py-3 px-6
                                rounded-lg mt-3 hover:bg-indigo-500 transition ease-in-out duration-300"
                            >
                              Submit
                            </button>
                          </form>
                        )}
                        </Formik>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </dialog>
        )
}

export default Modal
