import React from "react"

const ContactLeft = () => {
  return (
    <div className="p-6 mr-2 bg-gray-100 dark:bg-gray-800 sm:rounded-lg">
      <h4 className="text-3xl sm:text-3xl text-gray-800 dark:text-white font-extrabold tracking-tight">
        Get in touch
      </h4>
      <p className="text-normal text-lg sm:text-2xl mt-6 font-medium text-gray-600 dark:text-gray-400 mt-2">
        Fill in the form to start a conversation
      </p>

      <div className="flex items-center mt-8 text-gray-600 dark:text-gray-400">
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          className="w-8 h-8 text-gray-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        <div className="ml-4 text-md tracking-wide font-semibold text-left">
          Doug Cone c/o Synergy Mill
          <br />
          Suite C
          <br />
          &#52;00 Birnie Street Ext
          <br />
          Greenville, SC 2961&#55;
        </div>
      </div>

      <div className="flex items-center mt-4 text-gray-600 dark:text-gray-400">
        <svg
          version="1.1"
          id="Twitter"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          className="w-8 h-8 text-gray-500"
        >
          <path
            // eslint-disable-next-line max-len
            d="M17.316,6.246c0.008,0.162,0.011,0.326,0.011,0.488c0,4.99-3.797,10.742-10.74,10.742 c-2.133,0-4.116-0.625-5.787-1.697c0.296,0.035,0.596,0.053,0.9,0.053c1.77,0,3.397-0.604,4.688-1.615 c-1.651-0.031-3.046-1.121-3.526-2.621c0.23,0.043,0.467,0.066,0.71,0.066c0.345,0,0.679-0.045,0.995-0.131 C2.84,11.183,1.539,9.658,1.539,7.828c0-0.016,0-0.031,0-0.047c0.509,0.283,1.092,0.453,1.71,0.473 c-1.013-0.678-1.68-1.832-1.68-3.143c0-0.691,0.186-1.34,0.512-1.898C3.942,5.498,6.725,7,9.862,7.158 C9.798,6.881,9.765,6.594,9.765,6.297c0-2.084,1.689-3.773,3.774-3.773c1.086,0,2.067,0.457,2.756,1.191 c0.859-0.17,1.667-0.484,2.397-0.916c-0.282,0.881-0.881,1.621-1.66,2.088c0.764-0.092,1.49-0.293,2.168-0.594 C18.694,5.051,18.054,5.715,17.316,6.246z"
          />
        </svg>
        <div className="ml-4 text-md tracking-wide font-semibold text-left">
          <a href="https://twitter.com/nullvariable" rel="nofollow">@nullvariable</a>
        </div>
      </div>

      <div className="flex items-center mt-4 text-gray-600 dark:text-gray-400">
        <svg
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          className="w-8 h-8 text-gray-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            // eslint-disable-next-line max-len
            d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
          />
        </svg>
        <div className="ml-4 text-md tracking-wide font-semibold text-left">
          +&#49;
          {' '}
          <span>&#56;</span>
          64-&#53;61&#45;488
          <span className="hidden">4</span>
          <span>4</span>
        </div>
      </div>
    </div>
  )
}

export default ContactLeft
