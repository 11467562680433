import React from "react"
import PropTypes from 'prop-types'
import '../css/global.scss'
import { Helmet } from "react-helmet"
import Modal from "./Modal"

const Layout = ({ children }) => {
  return (
    <div
      id="GatsbyBody"
    >
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content="
            script-src
            'self'
            'unsafe-eval'
            'unsafe-inline'
            https://hcaptcha.com
            https://*.hcaptcha.com;
            frame-src
            'self'
            https://hcaptcha.com
            https://*.hcaptcha.com;
            style-src
            'self'
            'unsafe-inline'
            https://hcaptcha.com
            https://*.hcaptcha.com"
        />
        {/* https://openmoji.org/library/#search=computer&emoji=1F4BE */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=3" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=3" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=3" />
        <link rel="manifest" href="/site.webmanifest?v=3" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg?v=3" color="#000000" />
        <link rel="shortcut icon" href="/favicon.ico?v=3" />
        <meta name="apple-mobile-web-app-title" content="DougCone.com" />
        <meta name="application-name" content="DougCone.com" />
        <meta name="msapplication-TileColor" content="#2b5797" />
        <meta name="theme-color" content="#000000" />
      </Helmet>
      <main id="site-content" role="main">
        {children}
      </main>
      <Modal />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}

export default Layout
