import React from 'react'
import PropTypes from 'prop-types'
import { truncate } from "lodash"
import BlogThumb from "./BlogThumb"

const SmallPostCard = ({title, featuredImage, excerpt, uri}) => {
  const shortText = truncate(excerpt, {length: 35})

  return (
    <div
      className="flex justify-between items-center h-16 p-4 my-2 rounded-lg border border-gray-100 shadow-md"
    >
      <div className="">
        <BlogThumb featuredImage={featuredImage} />
        <div className="ml-2 float-left">
          <a href={uri} className="text-sm font-semibold text-gray-600">
            { truncate(title, {length: 30}) }
          </a>
          {(shortText.length > 0 && (
          <div
            className="text-sm font-light text-gray-500 -my-2"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: shortText }}
          />
          )
          )}
        </div>
      </div>
    </div>
  )
}

// const featuredImage = PropTypes.shape()

SmallPostCard.propTypes = {
  title: PropTypes.string.isRequired,
  featuredImage: PropTypes.shape({
    url: PropTypes.string,
    caption: PropTypes.string,
  }).isRequired,
  excerpt: PropTypes.string.isRequired,
  uri: PropTypes.string.isRequired
}

export default SmallPostCard
